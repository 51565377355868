import React from 'react'
import Face from '../Components/Face'
import EduModel from '../Components/EduModel'
import Courses from '../Components/Courses'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterLayout from '../Layouts/MasterLayout'
import SeoMetaTags from '../Seo'




  export default function Home(){
    const navigate = useNavigate();

      useEffect(() => {
        if (window.location.pathname === '/courses') {
          const element = document.getElementById('section-to-scroll');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [navigate]);
    return (
      <div>
        <SeoMetaTags
        title="SEOEM Akademi"
        description="SEOEM Akademiye hoş geldiniz. Bilişim ve teknoloji dünyasında sıfırdan uzmanlığa ilerleyebileceğiniz eğitimleri burada bulacaksınız."
        url="https://academy.seoem.co/"
        image="../Components/Face"
        />
        <MasterLayout>
        <Face/>
        <EduModel/>
        <div id='section-to-scroll'>
          <Courses/>
        </div>
        </MasterLayout>
      </div>
    )
  }

