import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faPeopleGroup, faCode } from '@fortawesome/free-solid-svg-icons';


export default class EduModel extends Component {
  render() {
    return (
        <div className='w-full bg-light3 my-10'>
            <div className="flex flex-col text-center justify-center py-4">
                <h3 className="text-seoem-100 text-2xl font-bold mb-4">Eğitim Modeli</h3>
                <div className="flex flex-col md:flex-row justify-evenly">
                <div className="text-center flex-1 mx-2 mb-4 md:mb-0">
                    <FontAwesomeIcon icon={faDesktop} className='text-red-700' />
                    <h4 className="text-seoem-100 text-lg font-bold mb-2">Uzaktan Eğitim</h4>
                    <p className="text-seoem-100">Senkron ve asenkron çevrimiçi eğitimlere erişin.</p>
                </div>
                <div className="text-center flex-1 mx-2 mb-4 md:mb-0">
                    <FontAwesomeIcon icon={faPeopleGroup} className='text-red-700'/>
                    <h4 className="text-seoem-100 text-lg font-bold mb-2">Takım Çalışması</h4>
                    <p className="text-seoem-100">Takım çalışmaları ile potansiyelinizi keşfedin.</p>
                </div>
                <div className="text-center flex-1 mx-2">
                    <FontAwesomeIcon icon={faCode} className='text-red-700' />
                    <h4 className="text-seoem-100 text-lg font-bold mb-2">Pratik Eğitim</h4>
                    <p className="text-seoem-100">Destekleyici projelerle kariyerinize yön verin.</p>
                </div>
                </div>
            </div>
        </div>
    )
  }
}
