import React from 'react';
import { Helmet } from 'react-helmet';

const SeoMetaTags = ({ title, description, url, image }) => {
  return (
    <Helmet>
      {/* Sayfa Başlığı */}
      <title>{title}</title>

      {/* Açıklama */}
      <meta name="description" content={description} />

      {/* Anahtar Kelimeler */}
      <meta name="keywords" content="veri bilimi, front-end, eğitim, react, SEO, SEOEM Academy, Python, numpy,pandas,matplotlib, veri görselleştirme, veri analizi,tailwindcss,sass" />

      {/* Site İsim ve Yazar */}
      <meta name="author" content="SEOEM Academy" />
      <meta name="publisher" content="SEOEM" />

      {/* Robots - Arama Motoru Talimatları */}
      <meta name="robots" content="index, follow" />

      {/* Viewport - Mobil Uyumluluk */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Open Graph (Facebook ve diğer sosyal medya platformları için) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="SEOEM Academy" />

      {/* Twitter Kartları (Twitter paylaşımı için) */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@seoemco" />

      {/* Canonical URL (Çift içerik problemlerini önlemek için) */}
      <link rel="canonical" href={url} />

      {/* Favicon */}
      <link rel="icon" href="../public/favicon.ico" type="image/x-icon" />
    </Helmet>
  );
};

export default SeoMetaTags;
