import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FRONT_END_2 from "./Courses/FrontEnd_2";

function App() {
  return (
    <div className="bg-[#f0f8ff]">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/courses" element={<Home/>}/>
        <Route path="/frontend" element={<FRONT_END_2/>}/>
      </Routes>
    </div>
  );
}

export default App;
