import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="bg-other-100 text-white py-6">
            <div className="flex max-lg:flex-col max-lg:items-center text-center justify-evenly">
                <div className="">
                    <h4 className="text-xl font-bold mb-2">İletişim Bilgileri</h4>
                    <address className='text-left'>
                        <p>
                            E-posta: <a href="mailto:contact@seoem.co" className="text-gray-100 hover:text-white">contact@seoem.co</a>
                        </p>
                        <p>Bizi Ziyaret Edin: <Link target='_blank' to={'https://www.seoem.co'}>seoem.co</Link></p>
                        <p>Adres: İstanbul, Türkiye</p>
                    </address>
                </div>
                <div className="">
                    <h4 className="text-xl font-bold mb-2">Hızlı Linkler</h4>
                    <ul>
                        <li><Link className='text-seoem-100 hover:text-white' to={'/'}>Ana Sayfa</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://www.seoem.co'}>Hakkımızda</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://www.seoem.co'}>Blog</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://www.seoem.co'}>SEOEM.CO</Link></li>
                    </ul>
                </div>
                <div className="">
                    <h4 className="text-xl font-bold mb-2">Sosyal Medya</h4>
                    <ul>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://www.linkedin.com/company/93213929'}>Linkedin</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://www.instagram.com/seoem.co'}>Instagram</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'#'}>Facebook</Link></li>
                        <li><Link target='_blank' className='text-seoem-100 hover:text-white' to={'https://x.com/seoemco'}>X</Link></li>
                    </ul>
                </div>
            </div>
            <div className="text-center mt-4 text-seoem-200">
                <p>© 2023 seoem.co. Tüm hakları saklıdır.</p>
            </div>
        </footer>
      </div>
    )
  }
}
