import React from 'react'
import Free from '../Components/Free';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


const MasterLayout = ({children}) => (
    <div>
        <Free/>
        <Navbar/>
        <main>{children}</main>
        <Footer/>
    </div>
);

export default MasterLayout;
