import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import colorLogo from '../assets/images/logo/Asset-8@400x.png';


export default class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className='flex max-lg:flex-col max-lg:justify-center lg:justify-around items-center lg:bg-other-100 text-white h-24'>

          <Link style={{ backgroundImage: `url(${colorLogo})` }} className="font-bold text-3xl bg-clip-text text-transparent bg-cover bg-center hover:opacity-80 transition-opacity duration-300 flex items-center max-lg:h-full" to="/">
            <h1>SEOEM Akademi</h1>
          </Link>

            <ul className='flex max-lg:justify-around bg-other-100 max-lg:w-full py-1'>
                <li className='max-lg:my-1'><Link  className='px-2 py-1 mx-2 rounded-md hover:bg-seoem-200 hover:opacity-90' to='/'>Ana Sayfa</Link></li>
                <li className='max-lg:my-1'><Link  className='px-2 py-1 mx-2 rounded-md hover:bg-seoem-200 hover:opacity-90' to='/courses'>Eğitimler</Link></li>
                <li className='max-lg:my-1'><Link  className='px-2 py-1 mx-2 rounded-md hover:bg-seoem-200 hover:opacity-90' to='https://www.seoem.co'>seoem.co</Link></li>
            </ul>
        </nav>
      </div>
    )
  }
}
