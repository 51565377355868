import React, { Component } from 'react'

export default class Free extends Component {
  render() {
    return (
      <div>
        <div className='text-center text-sm text-gray-400 bg-gray-900 py-2'>
            <span className='font-bold'>Free Palestine</span>
        </div>
        <div className='w-full h-2 flex'>
            <span className='bg-red-600 w-1/4'/>
            <span className='bg-black w-1/4'/>
            <span className='bg-white w-1/4'/>
            <span className='bg-green-700 w-1/4'/>
        </div>
        <hr/>
      </div>
    )
  }
}
