        import React from 'react'
        import MasterLayout from '../Layouts/MasterLayout'


        const CourseLayout = ({children}) => (
            <div>
                <MasterLayout>
                    {children}
                </MasterLayout>
            </div>
        );

        export default CourseLayout;
